import renderHelpers from '../shared/render-helpers';
import DataTableDefinition from '../shared/data-table-definition';

class ProductContentChanges {
  constructor(rootSelector) {
    this.$table = $(rootSelector);
    this.ajaxUrl = this.$table.attr('data-ajax-url');
    this.token = this.$table.attr('data-token');
    this.helpers = renderHelpers;
  }

  renderExport(data, type, row, meta) {
    const isExport = type === 'export';

    switch (row.attribute_name) {
      case 'Images':
        if (isExport) {
          return $(data)
            .filter('a')
            .map(function (index, elem) {
              return `• ${$(elem).attr('href')}`;
            })
            .get()
            .join('\n');
        } else {
          const images = $(data)
            .filter('a')
            .map(function (index, elem) {
              return `
              <a href="${$(elem).attr('href')}" target="_blank">
                <img src="${$(elem).attr('href')}" class="rounded border border-border" />
              </a>
            `;
            })
            .get()
            .join('');

          return `<div class="grid md:grid-cols-2 xl:grid-cols-3 gap-4">${images}</div>`;
        }
        break;

      case 'A+ Product Description':
      case 'A+ Batch':
      case 'A+ Brand Story':
      case 'A+ Sustainability':
      case 'Book Description':
      case 'Editorial Reviews':
        const text = data ? '✅' : '❌';
        const showComparison = meta.col === 4;
        const style = row.attribute_name === 'Book Description' || row.attribute_name === 'Editorial Reviews' ? 'html' : 'aplus';

        if (isExport) {
          return text;
        } else {
          return `
            <div class="flex flex-wrap gap-2">
              ${text}
              ${showComparison ? `
                <a class="link" href="javascript:void(0)"
                        data-dialog-toggle='aplus-compare-modal'
                        data-aplus-compare
                        data-aplus-compare-style="${style}"
                        data-aplus-compare-old=\"${(row.old_value || '').replace(/"/g, '&quot;')}\"
                        data-aplus-compare-new=\"${(row.new_value || '').replace(/"/g, '&quot;')}\"
                >
                  (Compare)
                </a>
              ` : ''}
            </div>
          `;
        }
        break;

      default:
        return data;
    }
  }

  initTable() {
    const buttons = [];
    if (window.IL_ROLE_PERMITS && window.IL_ROLE_PERMITS('general', 'export_tables')) {
      buttons.push({
        extend: 'ilExports',
        filename: 'Amazon Content Changes',
      });
    }

    this.dataTable = this.$table.DataTable({
      deferRender: true,
      lengthMenu: [5, 10, 25, 50],
      paging: true,
      pageLength: 5,
      dom: TORO.shared.dataTableDefaults.dom,
      ajax: {
        type: 'GET',
        url: this.ajaxUrl,
        data: (d) => {
          d.token = this.token;
        },
        dataSrc: 'data',
      },
      columns: [
        {
          name: 'date',
          data: 'crawled_at',
          render: this.helpers.renderDate,
        },
        {
          name: 'attribute',
          data: 'attribute_name',
          render: this.helpers.renderAttribute,
        },
        {
          name: 'attribute_action',
          data: 'attribute_action',
          render: this.helpers.renderAttributeAction,
        },
        {
          name: 'old',
          data: 'old_value',
          sortable: false,
          render: this.renderExport,
        },
        {
          name: 'new',
          data: 'new_value',
          sortable: false,
          render: this.renderExport,
        },
      ],
      language: TORO.shared.dataTableDefaults.language('changes'),
      buttons: buttons,
      order: [[0, 'desc']],
    });
  }

  init() {
    this.initTable();
  }
}

export default ProductContentChanges;
